.select-search {
    @apply relative;
}

.select-search .select-touch--search input{
    @apply bg-gray-100 border-0;
}

.select-search .input:read-only:not(:disabled){
    @apply bg-white;
}
