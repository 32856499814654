#sidebar-menu {
    @apply flex flex-col space-y-2;
}

.sidebar-block {
    @apply relative cursor-pointer;
}

.sidebar-block > svg.open {
    transform: rotate(0deg);
}

.sidebar-menu-item svg.anchor {
    transform: rotate(180deg);
    transition: 0.2s ease;
}
.sidebar-menu-item.open svg.anchor {
    transform: rotate(90deg);
    transition: 0.2s ease;
}

.sidebar-links {
    @apply overflow-hidden flex flex-col space-y-2;
    max-height: 0;
    transition: 0.2s ease;
}
.sidebar-links.open {
    max-height: 500px;
}
.sidebar-links.open {
    @apply mt-2;
}

.sidebar-menu-item {
    @apply items-center font-univers text-sm text-gray-600 rounded-md;
    position: relative;
}
.sidebar-menu-icon {
    @apply absolute inset-y-0 left-0 flex items-center pl-2 text-xl;
}

.sidebar-menu-arrow-icon {
    @apply absolute flex items-center pr-2 inset-y-0 right-0;
}

.sidebar-menu-label {
    @apply inline-block w-full py-2 pl-9 pr-4 rounded;
}
.sidebar-menu-item:hover {
    @apply bg-base-200 font-bold;
}

.sidebar-menu-item.active > a {
    @apply font-bold;
}

aside {
    border-right: 1px solid #e5e7eb;
}
