
.body__prices {
    display: flex;
    justify-content: space-between;
    background-color: #eeeeee;
    border-radius: 0.5rem;
    padding: .2rem 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.body__prices p{
    margin: .25rem;
}

.prices {
    text-align: center;
}

.title_price {
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .body__prices {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
        padding: 0;
        background-color: #fff;
    }

    .prices {
        background-color: #eeeeee;
        border-radius: .5rem;

    }

    .company_badge div{
        margin-top: 1rem;
        width: fit-content;
    }
}
