.modal--info {
    align-items: flex-start;
}
.modal--info .modal__window{
    margin-top: 1.5rem;
}

.list_info li p{
    margin: .25rem;
}

.body__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.card_info {
    background-color: #eeeeee;
    border-radius: 0.5rem;
    padding: .2rem 1.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.card_info h3 {
    margin-bottom: .5rem;
    color: var(--advance-color);
    font-size: 14px;
    font-weight: bold;
}

.list_info li{
    border-bottom: var(--separator-line);
}

.table_info {
    border-collapse: collapse;
    /* table-layout: fixed; */
    width: 100%;
}

.table_info tbody td, .table_info thead th {
    border-bottom: var(--separator-line);
    padding: 7px;
    text-align: left;
    font-weight: lighter;
}

.table_info tfoot td {
    padding: 7px;
    padding-bottom: 0;
    text-align: left;
    font-weight: lighter;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* company badge */
.company_badge {
    border: 1px solid #999999;
    padding: 5px 10px;
    border-radius: 1rem;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}
.company_badge img{
    height: 15px;
}

@media screen and (max-width: 576px) {
    .body__cards {
        grid-template-columns: 1fr;
    }

    .company_badge div{
        margin-top: 1rem;
        width: fit-content;
    }
}
