/*
* La tabla tiene dos estilos, el otro estilo lo encontrarás en el estilado del Panel Component
*/

.thead tr {
    @apply text-xs;
}

.thead th {
    @apply py-1.5 px-3 font-univers-light whitespace-nowrap bg-base-300 text-primary;
    text-transform: uppercase;
}

.thead tr span svg {
    @apply inline;
}

.tbody tr {
    @apply border-b hover:bg-gray-100;
}

.tbody tr.bg-error {
    @apply bg-red-50;
}

.tbody td{
    @apply py-4 px-3 whitespace-nowrap;
    animation: fadeIn .7s;
}
.tbody tr td:nth-child(1){
    text-align: left;
}


.tsort span.active {
    @apply font-univers-bold;
}

.svg-sort.desc {
    transform: rotateX(180deg);
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.datatable{
    @apply mt-4;
    overflow-y: visible;
    overflow-x: auto;
}

.datatable--form{
    overflow-x: visible;
}

.datatable.fix-head{
    overflow: auto;
}

.datatable.fix-head th{
    @apply top-0 sticky z-10;
}

.cell-actions{
    text-align: center;
    width: 150px;
}

.modal__window .datatable .thead th{
    @apply font-univers-bold;
    white-space: normal;
    padding: 0.5rem;
}

.modal__window .datatable .tbody td{
    white-space: normal;
    padding: 0.5rem;
}
