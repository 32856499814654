div.upload {
    @apply w-full h-48 flex border border-base-300 border-dashed rounded relative;
}

.upload.helper {
    @apply border-red-500;
}

.upload-input{
    @apply flex items-center justify-center h-full w-full;
}

.upload-files{
    @apply p-4 hidden bg-gray-50 h-full overflow-y-auto;
}

.upload-files--file{
    @apply w-full border rounded mb-2 bg-white;
}

.upload.has-file .upload-input {
    width: 230px;
}

.upload.has-file .upload-files {
    @apply block;
    width: calc(100% - 230px);
}

.upload-files .upload-files--file {
    @apply flex items-center px-4 py-3 relative justify-between;
}

.active-drag{
    @apply transition-all ease-in-out bg-base-200 opacity-100 visible
}

.desactive-background{
    @apply transition-all ease-in-out opacity-10
}
