.pagination {
  display: flex;
  justify-content: space-between;
  padding-top: .6em;
  padding-bottom: .6em;
  width: 100%;
  align-items: center;
}

.pagination .pagination-info, .pagination .pagination-info span{
  font-size: 12px;
  @apply text-gray-600;
}

.pagination-enumeration {
  display: flex;
}

.pagination-item {
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  @apply text-primary border-2 border-transparent;
}

.pagination-item:hover {
  @apply border-gray-200;
}

.pagination-item.active {
  pointer-events: none;
  font-weight: bold;
  @apply bg-gray-200 border-gray-200;
}
.pagination-item.disabled{
  pointer-events: none;
  opacity: .7;
}
.pagination-item.disabled:hover {
  @apply border-2 border-transparent;
}
