.upload-field{
    @apply border-2 border-dashed rounded flex items-center justify-center transition px-4;
    min-width: 200px;
    min-height: 40px;
}

.upload-field.disabled{
    @apply border-gray-200;
}

.upload-field.has-files{
    @apply justify-start border-none;
}
