:root {
    --advance-color: #2A2A5B;
    --gray-color: rgb(229,231,235);
    --separator-line: 1px solid rgb(156, 151, 151);
}

/* Modal */
.modal {
    background-color: #00000033;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    position: fixed;
}

.modal__window {
    display: block;
    box-sizing: border-box;
    font-size: 14px;
    max-height: calc(100vh - 1rem);
    width: 750px;
    border: 1px solid var(--gray-color);
    border-radius: 0.5rem;
    background-color: #fff;
    overflow-y: auto;
    overscroll-behavior-y: contain;
}
.modal--lg .modal__window{
    width: 1140px;
}
.modal--md .modal__window{
    width: 750px;
}
.modal--sm .modal__window{
    width: 500px;
}
.modal .modal__header {
    padding: 1rem 1rem;
    border-bottom: 1px solid var(--gray-color);
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}
.modal .modal__header .title {
    font-weight: 600;
    text-transform: uppercase;
}
.modal .modal__header button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: rgb(156, 151, 151);
}
.modal .modal__body {
    height: 100%;
    padding: 1rem 1.5rem;
}
.modal .modal__footer {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--gray-color);
    justify-content: flex-end;
    flex-direction: row;
}

/* Modal body*/
.modal__body .body__head {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
}
.body__head .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 90%;
}
.body__head > .title p {
    margin: 0;
    color: #666666;
}
.body__head > .title h1 {
    color: var(--advance-color);
    font-size: 1.25rem;
    margin-bottom: .3rem;
    font-weight: 700;
}
@media screen and (max-width: 576px) {
    .modal__body .body__head {
        grid-template-columns: 1fr;
        row-gap: 0.5rem;
    }

    .body__head .title {
        max-width: 100%;
    }
}

.modal.modal-open {
    visibility: visible;
}
