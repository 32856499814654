::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    @apply bg-gray-100 rounded-lg;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-200 rounded-lg;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
}

.notification-fade-in{
    animation-duration: .5s;
    animation-name: fadein-notification;
    transition: .5s ease-out; /* Ver tambien en ./utils/Notify.LIFE */
    position: relative;
}

.__react_component_tooltip{
    opacity: 1 !important;
}

@keyframes fadein-notification {
    0% {
        right: -50px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        right: 0;
    }
}

.zoom-in {
    animation-duration: .1s;
    animation-name: zoom-keyframes;
    transition: .1s ease-in; /* Ver tambien en ./utils/Notify.LIFE */
}

.country_img {
    width: 30px;
    height: 20px;
}

.z-select-options {
    z-index: 40;
}

@media (max-width: 992px) {
    .auto-hidden {
        display: none;
    }
}

@keyframes zoom-keyframes {
    from {
        transform: scale(.9);
    }

    to {
        transform: scale(1);
    }
}

.select-touch-inherit.select-touch{
    display: inherit;
}
