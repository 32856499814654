:root {
    font-family: Arial, Helvetica, sans-serif;
    --advance-color: #2A2A5B;
}

.login-page {
    background: linear-gradient(-45deg,#43257a,#2A2A5B);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-page .container {
    box-sizing: border-box;
    font-size: 14px;
    width: 400px;
    height: auto;
    border: 1px solid var(--gray-color);
    border-radius: 0.5rem;
    background-color: #fff;
    align-self: center;
}

.login-page .container__body {
    padding: 3rem;
}

.login-page .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.login-page .form_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem
}

.login-page .logo {
    align-self: center;
    width: 100px;
}

.login-page .title {
    font-weight: bold;
    font-size: 20px;
    align-self: center;
    color: #53575e;
    margin-bottom: 0;
}

.login-page .inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.login-page label {
    color: #6b7280;
}

.login-page .username, .password {
    height: 40px;
    border-radius: 0.25rem;
    padding: 0 1rem;
    outline: none;
    border: 1px solid #6b7280;
    font-size: 14px;
    
}

.login-page input::placeholder {
    color: #B0B6BF;
}

.login-page input:focus {
    box-shadow: 0 0 6px -2px #29a6ff;
    border-width: 1px;
    border-color: rgba(41,166,255,1);
}

.login-page .btn {
    height: 2.5rem;
    width: 302px;
    color: #fff;
    background-color: var(--advance-color);
    border: none;
    font-weight: bold;
    border-radius: .25rem;
    margin-top: 1rem;
}

.login-page .btn:hover {
    background-color: #1b1b3b;
    cursor: pointer;
}
