.ul{
    @apply list-disc list-inside font-univers-light text-sm;
}

.ul li:not(:last-child){
    @apply mb-4;
}

.ul li b{
    @apply font-univers-bold;
}