.modal{
    z-index: 30;
}
.modal.modal-open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
}

.modal.modal-open .modal-box {
    --tw-translate-y: 0;
}
.modal--lg .modal-box{
    max-width: 1140px;
}
.modal--md .modal-box{
    max-width: 750px;
}
.modal--sm .modal-box{
    max-width: 500px;
}
/* Datatable Component */
.modal .datatable table{
    border-spacing: 0;
    border-collapse: initial;
    @apply border border-t-0 rounded-lg;
}

.modal .datatable table .thead th:first-child{
    @apply rounded-tl-md;
}

.modal .datatable table .thead th:last-child{
    @apply rounded-tr-md;
}

.modal .datatable table .tbody td{
    @apply border-b;
}

.modal .datatable table .tbody tr:last-child td{
    @apply border-none;
}
