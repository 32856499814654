@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'univers';
  src: url('./assets/fonts/UniversLTStd.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'univers-light';
  src: url('./assets/fonts/UniversLTStdLight.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'univers-bold';
  src: url('./assets/fonts/UniversLTStdBold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'univers-cn';
  src: url('./assets/fonts/UniversLTStdCn.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'univers-light-cn';
  src: url('./assets/fonts/UniversLTStdLightCn.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'univers-bold-cn';
  src: url('./assets/fonts/UniversLTStdBoldCn.otf');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
