.mapview {
    @apply relative w-full border;
    height: 250px;
}

.mapview #google-map {
    @apply h-full;
}

.mapview .map-search{
    @apply absolute top-6 z-10;
    left: 10%;
    width: 80%;
}
