.badge-comp--large {
    height: 30px;
}

.badge-close {
    @apply justify-between;
}

.badge-comp img{
    max-width: 20px;
    @apply mr-2;
}
