.simple-tab {
    @apply capitalize flex-grow text-center leading-10 text-gray-500;
    background-color: #eeeeee;;
    cursor: pointer;
}

.simple-tab:hover {
    background-color: rgb(190, 190, 190);
}

.simple-tab--selected {
    @apply text-primary font-bold border-primary border-t-2;
    background-color: #fff;  
}

.simple-tab--selected:hover {
   background-color: #fff; 
}
