.triangle::before{
    content:"";
    display:inline-block;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    position:absolute;
}

.triangle.triangle-top::before{
    left:calc(50% - 5px);
    bottom:-7px;
    border-top:8px solid #FFFF;
}
.triangle.triangle-bottom::before{
    left:calc(50% - 5px);
    top:-7px;
}
.triangle.triangle-bottom-right::before{
    left:-3px;
    top:-3.5px;
    transform: rotate(-45deg);
}
.triangle.triangle-bottom-left::before{
    left:calc(100% - 6px);;
    top:-3.5px;
    transform: rotate(45deg);
}
.triangle.triangle-bottom.color-primary::before,.triangle.triangle-bottom-right.color-primary::before,.triangle.triangle-bottom-left.color-primary::before{
    border-bottom:8px solid theme(colors.primary);
}
.triangle.triangle-bottom.color-secondary::before,.triangle.triangle-bottom-right.color-secondary::before,.triangle.triangle-bottom-secondary.color-error::before{
    border-bottom:8px solid theme(colors.secondary);
}
.triangle.triangle-bottom.color-error::before,.triangle.triangle-bottom-right.color-error::before,.triangle.triangle-bottom-left.color-error::before{
    border-bottom:8px solid theme(colors.error);
}
.triangle.triangle-bottom.color-warning::before,.triangle.triangle-bottom-right.color-warning::before,.triangle.triangle-bottom-left.color-warning::before{
    border-bottom:8px solid theme(colors.warning);
}
.triangle.triangle-bottom.color-white::before,.triangle.triangle-bottom-right.color-white::before,.triangle.triangle-bottom-left.color-white::before{
    border-bottom:8px solid #FFFF;
}
.triangle.triangle-bottom.color-info::before,.triangle.triangle-bottom-right.color-info::before,.triangle.triangle-bottom-left.color-info::before{
    border-bottom:8px solid theme(colors.info);
}
.triangle.triangle-bottom.color-success::before,.triangle.triangle-bottom-right.color-success::before,.triangle.triangle-bottom-left.color-success::before{
    border-bottom: 8px solid theme(colors.success);
}


