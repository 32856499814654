.drop-image {
    @apply relative h-full w-full transition border-2 rounded border-dashed;
}

.drop-image .remove{
    @apply absolute rounded-full w-8 h-8 flex justify-center items-center z-10 bg-error cursor-pointer shadow-md;
    top: calc(50% - 16px);
    right: calc(50% - 16px);
}

.drop-image .content-image{
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.drop-image .content-image img{
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
}
