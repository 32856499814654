.btn.btn-normal{
    min-height: 40px;
    height: 40px;
}

.btn.btn-sm{
    @apply normal-case rounded;
}

.group-btn-options{
    @apply flex flex-wrap justify-center gap-1;
}

.btn-option{
    border: 0.5px solid #4b5563;
    padding: 5px;
    border-radius: 999px;
    display: flex;
    cursor: pointer;
}

.btn-option:hover{
    background-color: #dddbdb;
}

.btn-option:disabled,
.btn-option[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.input:disabled,
input[disabled]{
    background-color: #d2d4d7!important;
}

.input, .textarea {
    @apply focus:shadow-none rounded;
}

.input:read-only{
    @apply bg-base-200;
}

.input:focus, .textarea:focus {
    box-shadow: 0 0 6px -2px theme('colors.accent');
    @apply border border-accent;
}

.input.input-error, .textarea.textarea-error{
    @apply border border-red-500;
}

.input.input-error:focus, .textarea.textarea-error:focus{
    box-shadow: 0 0 6px -2px theme('colors.error');
}

.checkbox {
    @apply rounded;
}

.checkbox-sm {
    min-width: 20px;
}

.toggle-sm{
    min-width: 2rem;
}

.modal-box{
    @apply rounded-lg;
}

.alert {
    @apply rounded-lg;
}
