.select-touch .select-touch--search input{
    @apply bg-gray-100 border-0;
}

.select-input{
    @apply border w-full rounded-md flex items-center pl-4 flex-wrap pt-2 pb-1 cursor-text;
    min-height: 40px;
}

.select-input.helper{
    @apply border-red-500;
}

.select-input .badge-comp{
    @apply mb-1;
}

.select-input.disabled {
    @apply bg-base-200 cursor-not-allowed border-transparent;
}