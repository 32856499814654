.dialog.dialog-open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    z-index: 9999;
}

.dialog.dialog-open .modal-box {
    --tw-translate-y: 0;
}
