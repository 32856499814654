.excel-field{
    @apply border border-dashed h-40 flex justify-center items-center flex-col relative;
}

.excel-field.helper {
    @apply border-error;
}

.excel-field p {
    @apply text-center text-gray-500 text-xs;
    max-width: 250px;
}

.excel-field input {
    @apply absolute w-full h-full opacity-0 cursor-pointer;
}
