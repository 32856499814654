.selector{
    max-width: 90px;
}

.selector ul, .selector input{
    @apply text-xs;
    @apply py-3;
    @apply px-4;
}

.selector input{
    @apply text-center rounded-md shadow-none p-0;
}

.selector-select-month{
    width:105px;
}
.selector-select-year{
    width:70px;
}

.datepicker .input:read-only:not(:disabled){
    @apply bg-white;
}

.input-md{
    height: 40px;
}
