.steps-comp{
    counter-reset: step;
    @apply bg-white w-full rounded-xl flex justify-between items-center h-24 px-36 mb-4 border-2 mx-auto pb-6;
}

.steps-comp li{
    @apply flex items-center w-full mr-1 text-success cursor-pointer text-sm;
}

.steps-comp li:last-child{
    @apply w-auto mr-0;
}

.steps-comp li.active {
    @apply text-accent;
}

.steps-comp li.active ~ li{
    @apply text-gray-500;
}

.steps-comp li::before{
    counter-increment: step;
    content: counter(step);
    width: 25px;
    min-width: 25px;
    height: 25px;
    @apply rounded-full text-white bg-success flex justify-center items-center text-xs;
}

.steps-comp li.active::before{
    @apply bg-accent;
}

.steps-comp li.active ~ li::before{
    @apply text-accent mr-0 bg-transparent;
}

.steps-comp li::after{
    content: "";
    @apply mx-5 w-full border-2 border-dashed border-success mt-3;
}

.steps-comp li.active::after, .steps-comp li.active ~ li::after {
    @apply border-gray-300;
}

.steps-comp li:last-child::after{
    @apply border-none w-0 mx-0;
}
