.panel {
    @apply bg-white rounded-xl w-full mx-auto mb-4 border;
}

.panel.panel--normal{
    @apply max-w-full;
}

.panel.panel--form-lg{
    @apply max-w-screen-lg;
}

.panel.panel--form {
    @apply max-w-screen-2xl;
}

.container_page--form{
    position: sticky;
}

.panel .panel-header{
    @apply flex justify-between items-center border-b py-4;
}

/* Mode: Normal */

.panel.panel--normal .panel-header, .panel.panel--form-lg .panel-header{
    @apply px-10;
}

.panel.panel--normal .panel-body{
    @apply p-4;
}

.panel.panel--form-lg .panel-body{
    @apply px-10 pt-4 pb-16;
}

/* Mode: Form */

.panel.panel--form .panel-header{
    @apply px-10;
}

.panel.panel--form .panel-body{
    @apply px-10 pt-4 pb-12;
}

/* Datatable Component */
/* If Panel is mode form */

.panel--form .panel--form-datatable table{
    border-spacing: 0;
    border-collapse: initial;
    @apply border border-t-0 rounded-lg;
}

.panel--form .panel--form-datatable table .thead th{
    @apply py-3;
}

.panel--form .panel--form-datatable table .thead th:first-child{
    @apply rounded-tl-md;
}

.panel--form .panel--form-datatable table .thead th:last-child{
    @apply rounded-tr-md;
}

.panel--form .panel--form-datatable table .tbody td{
    @apply border-b;
}

.panel--form .panel--form-datatable table .tbody tr:last-child td{
    @apply border-none;
}
